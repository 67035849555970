aside#logo-scroller {
  padding: var(--spacer0) 0;
  box-shadow: 0px -1px var(--border-top-colour, #dcdcdc);
}
aside#logo-scroller::before {
  box-shadow: inherit;
}

logo-scroller {
  --logo-width: 90px;
  --scroll-duration: 25s;
  --title-y-position: calc(var(--gap) * -0.5);
  --min-width: auto;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: clamp(125px, 15vw, 145px);
}
@media (hover: hover) {
  logo-scroller:hover ul {
    animation-play-state: paused !important;
  }
}
logo-scroller.in-view ul {
  animation-play-state: running;
}
logo-scroller ul {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  min-width: var(--min-width);
  animation: var(--scroll-duration) linear 0s infinite paused logo-scroller;
}
@keyframes logo-scroller {
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@media (min-width: 1681px) {
  logo-scroller ul {
    --min-width:100vw;
  }
}
logo-scroller ul li {
  position: relative;
  height: 100%;
  display: flex;
  place-content: center;
  place-items: center;
}
logo-scroller ul li .item {
  text-decoration: none;
  display: block;
  height: inherit;
  display: flex;
  place-content: center;
  place-items: center;
  padding: var(--gutter1);
  border: 1px solid transparent;
  transition: border-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
logo-scroller ul li .item::after {
  content: attr(data-title);
  white-space: nowrap;
  position: absolute;
  top: 100%;
  border: 1px solid #dcdcdc;
  left: 50%;
  background: white;
  font-size: 0.75em;
  padding: 5px 10px;
  opacity: 0;
  transform: translate(-50%, var(--title-y-position));
  transition: transform var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  color: var(--primary, #131f6b);
}
logo-scroller ul li .item[target=_blank]::after {
  padding-right: 34px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23DF3035' d='M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z'/%3E%3C/svg%3E");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
@media (hover: hover) {
  logo-scroller ul li .item:hover {
    border-color: #dcdcdc;
  }
  logo-scroller ul li .item:hover::after {
    --title-y-position: calc(-100% - calc(var(--gap) * 0.5));
    opacity: 1;
  }
  logo-scroller ul li .item:hover img {
    opacity: 1;
    filter: grayscale(0%);
  }
}
logo-scroller ul img {
  max-width: var(--logo-width);
  width: 15vw;
  opacity: 0.7;
  filter: grayscale(100%);
  transition: filter var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, ), opacity var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}

main logo-scroller {
  --title-y-position: -110%;
  width: 100%;
  padding: 0;
  mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
}

body > #logo-scroller logo-scroller {
  --title-y-position: -50%;
  left: var(--gutter1-);
}
@media (min-width: 321px) {
  body > #logo-scroller logo-scroller {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}

side-bar logo-scroller {
  width: 100%;
  padding-top: var(--gutter0);
  padding-bottom: var(--gutter0);
  --logo-width:50px;
}
@media (min-width: 1681px) {
  side-bar logo-scroller ul {
    --min-width: auto !important;
  }
}
@media (max-width: 980px) {
  side-bar logo-scroller {
    display: none;
  }
}