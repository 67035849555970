@use '_modules' as *;
@use 'modules/colour/encode' as colour;

@function base64-external-link($colour) {
  @return url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='"+colour.encode($colour)+"' d='M5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-7h-2v7H5V5h7V3H5zm9 0v2h3.586l-9.293 9.293 1.414 1.414L19 6.414V10h2V3h-7z'/%3E%3C/svg%3E");
}

aside#logo-scroller {
  padding:$spacer0 0; 
  box-shadow: 0px -1px var(--border-top-colour, #{$grey4});
  &::before { box-shadow: inherit; }
}

logo-scroller {

  --logo-width   : 90px;
  --scroll-duration  : 25s;
  --title-y-position : #{$gap0-}; 
  --min-width        : auto; 
  
  display:flex;
  position: relative;
  overflow: hidden;

  min-height:clamp(125px, 15vw, 145px);

  @include hover {
    ul { animation-play-state: paused !important; }
  }
  
  // &.load-template-in-view {
  //   min-height:240px; 
  // }

  &.in-view ul {
    animation-play-state: running;
  }

  ul { 
    display:flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    min-width:var(--min-width);

    @include animation-keyframes('logo-scroller') {
      100% { transform:translate3d(-100%, 0, 0) }
    }

    @include break-out(max) { --min-width:100vw; }
    
    animation: var(--scroll-duration) linear 0s infinite paused logo-scroller;

    li { 
      position:relative; 
      height:100%; 
      display:flex; 
      place-content: center;
      place-items: center;

      .item {
        text-decoration: none;
        display:block; 
        height:inherit; 
        display:flex; 
        place-content: center;
        place-items: center;
        padding:$gutter;
        border:1px solid transparent;
        transition: border-color $transition;

        &::after {
          content:attr(data-title);
          white-space: nowrap;
          position: absolute;
          top:100%;
          border:1px solid $grey4; 
          left:50%;
          background:white; 
          font-size: font-size(12);
          padding:5px 10px; 
          opacity:0; 
          transform : translate(-50%, var(--title-y-position));
          transition: transform $transition, opacity $transition;
          color:$primary;
        }

        &[target="_blank"]::after {
          padding-right:10px + 16px + 8px; // padding + icon size + additional padding
          background-image: base64-external-link($red);
          background-size:16px 16px;
          background-repeat: no-repeat;
          background-position:calc(100% - 10px) center;
        }

        @include hover {
          &::after { 
            --title-y-position : calc(-100% - #{$gap0});
            opacity:1; 
          }
          border-color: $grey4;
          img {
            opacity:1;
            filter: grayscale(0%);
          }
        }
      }
    }

    img { 
      max-width:var(--logo-width);
      width:15vw;
      opacity:0.7;
      filter: grayscale(100%);
      transition: filter $transition, opacity $transition;
    }
  }
}

/// --------------------------------------------------------------------------
/// Main Component
/// --------------------------------------------------------------------------

main logo-scroller {
  --title-y-position : -110%;
  width:100%;
  padding:0; 
  mask-image:  linear-gradient(to right, transparent, black 20%, black 80%, transparent);
}

/// --------------------------------------------------------------------------
/// Root Component
/// --------------------------------------------------------------------------

body > #logo-scroller logo-scroller {
  --title-y-position : -50%;
  left : $gutter-;
  @include break-out(min) {
    width:100vw; 
    left:50%;
    transform:translateX(-50%);
  }
}

/// --------------------------------------------------------------------------
/// Sidebar Component
/// --------------------------------------------------------------------------

side-bar logo-scroller {
  width:100%;
  padding-top:$gutter0;
  padding-bottom:$gutter0;
  --logo-width:50px; 
  @include break-out(max) { ul { --min-width : auto !important; }}
  @include break-in('small-medium') { display:none; }
}